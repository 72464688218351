import { RootState } from './store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ITopBarState {
  buttonState:'default'|'back';
}

const initialState: ITopBarState = {
  buttonState: 'default',
};

const TopBarStateSlice = createSlice({
  name: 'topBarState',
  initialState,
  reducers: {
    setButtonState: (state, action: PayloadAction<ITopBarState['buttonState']>) => {
      state.buttonState = action.payload;
    },
  },
});

export const topBarActions = TopBarStateSlice.actions;

export const topBarSelectors = {
  buttonState: (state: RootState) => state.topBarState.buttonState,
};

export default TopBarStateSlice.reducer;
