import {RootState} from './store';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {MetaforestNftInfo} from "@/data/graphql/sdk/graphql";

export interface IWorkSector {
    sectorId: number;
}

const initialState: IWorkSector = {
    sectorId: 1,
};

const WorkSectorSlice = createSlice({
    name: 'workSector',
    initialState,
    reducers: {
        setSectorId: (state, action: PayloadAction<number>) => {
            state.sectorId = action.payload;
        },
    },
});

export const workSectorActions = WorkSectorSlice.actions;

export const workSectorSelectors = {
    sectorId: (state: RootState) => state.workSector.sectorId,
};

export default WorkSectorSlice.reducer;
