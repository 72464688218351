import {RootState} from './store';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {MetaforestNftInfo} from "@/data/graphql/sdk/graphql";

export interface IBreedSettings {
    enableBreedMode: boolean;
    firstBunny: MetaforestNftInfo | null
    secondBunny: MetaforestNftInfo | null
    level:number|null
    activeSlot:null|1|2
}

const initialState: IBreedSettings = {
    enableBreedMode: false,
    firstBunny: null,
    secondBunny: null,
    level:null,
    activeSlot:null
};

const BreedSettingsSlice = createSlice({
    name: 'breedSettings',
    initialState,
    reducers: {
        setEnableMode: (state, action: PayloadAction<boolean>) => {
            state.enableBreedMode = action.payload;
        },
        setFirstBunny:(state, action: PayloadAction<MetaforestNftInfo|null>) => {
            state.firstBunny = action.payload;
        },
        setSecondBunny:(state, action: PayloadAction<MetaforestNftInfo|null>) => {
            state.secondBunny = action.payload;
        },
        setLevel:(state, action: PayloadAction<number|null>) => {
            state.level = action.payload;
        },
        setActiveSlot:(state, action: PayloadAction<null|1|2>) => {
            state.activeSlot = action.payload;
        }
    },
});

export const breedSettingsActions = BreedSettingsSlice.actions;

export const breedSettingsSelectors = {
    enableBreedMode: (state: RootState) => state.breedSettings.enableBreedMode,
    firstBunny: (state: RootState) => state.breedSettings.firstBunny,
    secondBunny: (state: RootState) => state.breedSettings.secondBunny,
    level: (state: RootState) => state.breedSettings.level,
    activeSlot:(state:RootState)=>state.breedSettings.activeSlot

};

export default BreedSettingsSlice.reducer;
