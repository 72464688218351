import { RootState } from './store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IBunnyChangeSlider {
  position:number;
}

const initialState: IBunnyChangeSlider = {
  position:0,
};

const BunnyChangeSliderSlice = createSlice({
  name: 'bunnyChangeSlider',
  initialState,
  reducers: {
    setPosition: (state, action: PayloadAction<IBunnyChangeSlider['position']>) => {
      state.position = action.payload;
    },
  },
});

export const bunnyChangeSliderPositionActions = BunnyChangeSliderSlice.actions;

export const bunnyChangeSliderPositionSelectors = {
  position: (state: RootState) => state.bunnyChangeSliderPosition.position,
};

export default BunnyChangeSliderSlice.reducer;
