import '../styles/globals.css'
import type {AppProps} from 'next/app'
import ReduxProvide from "../components/shared/store/ReduxProvider";
import {TonConnectUIProvider} from "@tonconnect/ui-react";

function MyApp({Component, pageProps}: AppProps) {
    return (
        <TonConnectUIProvider
            manifestUrl='https://raw.githubusercontent.com/whanarchyven/ton-connect-manifest/main/tonconnect-manifest.json'>
            <ReduxProvide>
                <Component {...pageProps} />
            </ReduxProvide>
        </TonConnectUIProvider>
    )
}

export default MyApp
